import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import get from 'lodash/get'
import { MDBContainer } from 'mdbreact'
import SEO from '../components/seo'
import Hero from '../components/hero'
import BlogContainer from '../components/blogContainerStyled'


const BlogSearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData  
  const data = get(props, 'data')
  const heroImage = get(props, 'data.heroImage')
  const site = get(props, 'data.site')   
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title="Blog"
          description="Receive OEM technology updates and insights from one of the most trusted, capable system integration partners in the industry. Subscribe today!"
          url={`${data.site.siteMetadata.siteUrl}/blog/`} 
          image={ data.site.siteMetadata.siteUrl + heroImage.childImageSharp.fluid.src }
        />
        <Hero
          class="intro-50"
          image={ heroImage.childImageSharp.fluid }
          title="UNICOM Engineering blog"
          subtitle="OEM technology updates and insights."
          type="blog"
        />
      </div>

      <BlogContainer 
        content={data.allMarkdownRemark.edges} 
        filters={data.allMarkdownRemark.filters} 
        filterId="all" 
        searchContent={allData} 
        searchEngine={options}
      />
    </Layout>
  )
}
export default BlogSearchTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "blog" } } }
    ) {
      filters: distinct(field: frontmatter___tags)
      edges {
        node {
          id
          html
          frontmatter {
            title
            date
            author
            description
            readtime
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageFeatured {
              childImageSharp {
                fluid(maxWidth: 900, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "blog-hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`