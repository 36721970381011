import React from 'react'
import Link from 'gatsby-link'
import BackgroundImage from 'gatsby-background-image'
import { MDBMask, MDBRow, MDBCol, MDBView, MDBContainer, MDBSmoothScroll } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class newhero extends React.Component {
  render() {
    return (
      <BackgroundImage
        Tag="section"
        className={this.props.class}
        fluid={this.props.image}
        alt={this.props.imageAltText}
      >

        <MDBView>
          <MDBMask className={
                  this.props.gradientClass != null
                    ? this.props.gradientClass
                    : 'mask d-flex justify-content-center gradient'
                }>
            <MDBContainer>
              <MDBRow className="align-items-center h-100">
                {this.props.type === 'home' || this.props.type === '404' ?
                  <>
                    <MDBCol lg="6" className="white-text text-lg-left text-center mb-5">

                        <h1 className="font-alt font-w-700 text-white title-xs-extra-large title-extra-large-1 mt-sm-5 mb-xl-4"
                            dangerouslySetInnerHTML={{ __html: this.props.title }}                         
                        />
        
                        <h2 className="font-alt font-w-400 mb-4 title-xs-medium title-small letter-spacing-1" 
                            dangerouslySetInnerHTML={{ __html: this.props.subtitle }}                         
                        />

                        {this.props.ctaslug != '' && this.props.ctaslug != null && (
                            this.props.ctaslug == "main" ? 
                            (
                            <MDBSmoothScroll 
                              to={this.props.ctaslug}
                              className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                            >
                              <FontAwesomeIcon icon={faChevronRight} />{' '}
                              {this.props.ctatext != '' && this.props.ctatext != null
                                ? this.props.ctatext
                                : 'Read'}
                            </MDBSmoothScroll>
                            )
                            :
                            ( 
                            <Link 
                              to={this.props.ctaslug}
                              className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                            >
                              <FontAwesomeIcon icon={faChevronRight} />{' '}
                              {this.props.ctatext != '' && this.props.ctatext != null
                                ? this.props.ctatext
                                : 'Read'}
                            </Link>
                            )
                        )}                    
                      </MDBCol>    
                  </>  
              : this.props.type === 'news' ?
                  <MDBCol lg="12" className="white-text col-md-12 mt-xl-2">
                    <h1 className="font-alt font-w-700 text-white title-xs-extra-large title-extra-large-1 mt-sm-5 mb-xl-4" 
                        dangerouslySetInnerHTML={{ __html: this.props.title }} 
                    />
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                        <h2 className="font-alt font-w-400 mb-4 title-xs-medium title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: this.props.subtitle }} 
                        />
                    )}

                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                        <Link
                          to={this.props.ctaslug}
                          className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />{' '}
                          {this.props.ctatext != '' && this.props.ctatext != null
                            ? this.props.ctatext
                            : 'Read'}
                        </Link>
                    )}
                  </MDBCol>                        
              :
                  <MDBCol lg="6" className="white-text col-md-12 mt-xl-2">
                    <h1
                      className="font-alt font-w-700 text-white title-xs-extra-large title-extra-large-1 mt-sm-5 mb-xl-4"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                      />
                      {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2 className="font-alt font-w-400 mb-4 title-xs-medium title-small letter-spacing-1 mt-5 mb-xl-5"
                      dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
                    />
                    )}

                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                        <Link
                          to={this.props.ctaslug}
                          className="btn btn-mdb-color mr-0 mt-xl-3"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />{' '}
                          {this.props.ctatext != '' && this.props.ctatext != null
                            ? this.props.ctatext
                            : 'Read'}
                        </Link>
                    )}
                  </MDBCol>
                }

              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </BackgroundImage>
    )
  }
}

export default newhero