import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import ClientSearch from '../components/ClientSearch'
import BlogCardItems from './cardBlog'
import BlogAllItems from './blogAllItems'
import ColumnAlternatingStyled from './columnAlternatingStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

export default class BlogContainer extends Component {
  constructor(props, pageContext) {
    super(props)
    this.state = {
      content: this.props.content.sort((a, b) => a.node.frontmatter.date == b.node.frontmatter.date ? 0 : a.node.frontmatter.date > b.node.frontmatter.date ? -1 : 1),
      contentData: pageContext, 
      blogPosts: [],
      filters: [],
      filterActive: this.props.filterId  
    }
    //this.handleClick = this.handleClick.bind(this)
  }
   
  componentDidMount() {
    this.setState({
      blogPosts: this.state.content,
      filters: this.state.filters,
      filterActive: this.props.filterId 
    })
    this.handleClick(this.props.filterId)

    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        // console.log('Element:', entry.target);
        // console.log(`Element size: ${cr.width}px x ${cr.height}px`);
        // console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
        if (cr.height > 140) {
          document.getElementById('filter').style="display:none" 
        } else {
          if (cr.height) {
            document.getElementById('filter').style="display:" 
          } 
        }
      }
    });
    
    // Observe one or multiple elements
    if (document.getElementById('search')) {
      ro.observe(document.getElementById('search'))          
    } else {
      ro.disconnect()
    }

    // ro.observe(document.getElementById('search'))
  }

  handleClick(value) {
    let blogPosts = []
    this.setState({ filterActive: value })
    
    if (value === 'all') {
      blogPosts = this.state.content
    } else {
      blogPosts = this.state.content.filter(content => JSON.stringify(content.node.frontmatter.tags).match(value))
    }

    blogPosts = blogPosts.sort((a, b) => a.node.frontmatter.date == b.node.frontmatter.date ? 0 : a.node.frontmatter.date > b.node.frontmatter.date ? -1 : 1)
    this.setState({ blogPosts: blogPosts })
  }

  render() {
    const { allData, options } = this.state.contentData
    const blog = this.props.content
    const mode = 'all'
    var tags = ''

    return (
      <>
        {/* Filter */}
        <section id="search" className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <ClientSearch content={this.props.searchContent} engine={this.props.searchEngine} context="blog" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section id="filter" className="bg-white pt-0">
          <MDBContainer>
            <MDBRow className="pb-5">
              <MDBCol md="12" className="pl-0 justify-content-center filter-categories">
                <FontAwesomeIcon icon={faFilter} />
                <select className="browser-default custom-select ml-3 mr-2 w-75"  onChange={(e) => this.handleClick(e.target.value)}>
                <option value={"all"}>All categories</option>
                  {this.props.filters.map((tag, index) => {
                    return (
                      <option key={index} value={tag}>{tag}</option>
                    )
                  })}
                </select>
              </MDBCol>
            </MDBRow>

            {/* Post 1 */}
            {this.state.blogPosts.slice(0, 1).map(({ node }, index) => {
              return (
                <ColumnAlternatingStyled
                  key={index}
                  textCol="7"
                  imageCol="5"
                  title={node.frontmatter.title}
                  subtitle={node.frontmatter.title}
                  subtitlecolour={"red"}
                  description={node.frontmatter.description}
                  imageUrl={node.frontmatter.imageFeatured ? node.frontmatter.imageFeatured.childImageSharp.fluid : node.frontmatter.image.childImageSharp.fluid }
                  alt={node.frontmatter.title}
                  placement="right"
                  titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium"
                  displaytitle="d-none"
                  link={node.fields.slug}
                  date={moment(node.frontmatter.date).format('YYYY/MM/DD')} 
                  author={node.frontmatter.author}
                  readtime={node.frontmatter.readtime}                
                />
              )
            })}

            {/* Posts 2-3 */}
            <MDBRow className="py-4">
              {this.state.blogPosts.slice(1,3).map(({ node }, index) => {
                return (
                  <MDBCol md="12" lg="6" className="d-flex" key={index}>
                    <BlogCardItems
                      key={index}
                      collg="6"
                      colmd="6"
                      link={node.fields.slug}
                      imageUrl={node.frontmatter.image.childImageSharp.fluid}
                      alt={node.frontmatter.title}
                      title={node.frontmatter.title}
                      description={node.frontmatter.description}
                      date={node.frontmatter.date}
                      author={node.frontmatter.author}
                      readtime={node.frontmatter.readtime}
                    />
                  </MDBCol>
                )
              })}
            </MDBRow>

            {/* Remaining posts */}
            <MDBRow>
            {this.state.blogPosts.slice(3).map(({ node }, index) => {
              return (
                <BlogAllItems
                  key={index}
                  link={node.fields.slug}
                  title={node.frontmatter.title}
                  description={node.frontmatter.description}
                  date={node.frontmatter.date}
                  readtime={node.frontmatter.readtime}
                  author={node.frontmatter.author}
                />
              )
            })}
            </MDBRow>
          </MDBContainer>
        </section>
      </>
    )
  }
}